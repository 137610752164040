$font-location: "../assets/fonts";
$font-display: "swap";

@font-face {
    font-family: "Inter";
    src: local("Inter Bold"), local("Inter-Bold"),
        url($font-location + "/Inter/Inter-Bold.woff2") format("woff2"),
        url($font-location + "/Inter/Inter-Bold.woff") format("woff"),
        url($font-location + "/Inter/Inter-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: $font-display;
}

@font-face {
    font-family: "Quicksand";
    src: local("Quicksand Medium"), local("Quicksand-Medium"),
        url($font-location + "/Quicksand/Quicksand-Medium.woff2") format("woff2"),
        url($font-location + "/Quicksand/Quicksand-Medium.woff") format("woff"),
        url($font-location + "/Quicksand/Quicksand-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: $font-display;
}
