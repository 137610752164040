@import "../../../../styles/variables/layout";
@import "../../../../styles/variables/colors";

.content {
    margin: spacing(2, 4) 0;

    overflow-y: overlay;

    height: 0;

    flex-grow: 1;
}
