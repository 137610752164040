@import "../../../../styles/variables/colors";
@import "../../../../styles/variables/layout";

.container {
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    align-items: center;

    cursor: pointer;

    height: 55px;
    width: 100%;

    &:not(&:last-child) {
        margin: 0 0 spacing(0, 5);
    }

    padding: 15px 17px 15px 15px;

    border-radius: 8px;

    box-shadow: 1px 2px 6px 0 $base-shadow-color;
}

.name {
    display: flex;
    flex-direction: column;
    margin-left: spacing(1, -1);
    flex-grow: 1;
    justify-content: center;
}

.state {
    &1 {
        background-color: $schoonster-orange-20;
    }

    &2 {
        background-color: $violet-20;
    }
}

.loading {
    margin: auto;
}
