@import "src/styles/variables/colors";

.container {
    color: $base-text-color;
    font-size: 16px;

    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.isSize {
    &Small {
        font-size: 12px;
    }

    &Large {
        font-size: 24px;
    }
}

.isStyle {
    &Italic {
        font-style: italic;
    }
}

.isFont {
    &Secondary {
        font-family: 'Inter';
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }
}

.isColor {
    &Secondary {
        color: $secondary-text-color;
    }
}
