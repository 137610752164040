@import "../../../../styles/variables/colors";
@import "../../../../styles/variables/layout";

.container {
    position: relative;

    height: calc(var(--app-width) * 0.222);

    padding: spacing(2, 4);

    border-radius: 4px;

    background-color: $white-5;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

    cursor: pointer;

    margin: 0 calc(var(--app-width) * 0.186) spacing(0, 5) calc(var(--app-width) * 0.186);

    &.isSelected {
        height: calc(var(--app-width) * 0.319);

        flex-direction: column;

        align-items: baseline;

        margin: 0 calc(var(--app-width) * 0.158) spacing(0, 5) calc(var(--app-width) * 0.158);

        background-color: $white;
    }
}

.compactContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
}

.compaxtText {
    color: $white !important;
}

.counts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.count {
    display: flex;
    flex-direction: row;
}

.countValue {
    display: flex;
    flex-direction: column;
}

.indicator {
    width: 10px;
    height: 10px;
    margin: spacing(1,2) spacing(0, 5) spacing(1,2) 0;
    border-radius: 50%;

    &1 {
        background-color: $schoonster-orange-70;
    }

    &2 {
        background-color: $violet;
    }

    &3 {
        background-color: $green;
    }
}
