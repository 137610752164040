@import "../../../../styles/variables/layout";
@import "../../../../styles/variables/colors";

.floorsContainer {
    position: relative;

    display: flex;
    flex-direction: column;

    align-items: center;

    background-color: $base-background-color;

    z-index: 1;
}

.stickyFloorsContainer {
    position: sticky;
    top: calc(var(--app-width) * -1.22);
}

.floorCompact {
    position: absolute;
    display: none;

    bottom: calc(var(--app-width) * 0.1);

    padding: spacing(2, 4);

    width: 100%;
}

.stickyFloorCompact {
    display: block;
    animation-name: fadeIn;
    animation-duration: 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    40% {
        opacity: 0.3;
    }
    60% {
        opacity: 0.5;
    }
    80% {
        opacity: 0.9;
    }
    100% {
        opacity: 1;
    }
}

.floorsContent {
    position: absolute;

    overflow-y: overlay;

    height: calc(var(--app-width) * 0.8);

    bottom: calc(var(--app-width) * 0.25);

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    width: 100%;
}

.backHeader {
    position: absolute;

    padding: spacing(2, 4);

    width: 100%;
}

.buttonUp {
    position: absolute;

    width: 100%;

    bottom: 0px;
}

.loading {
    margin: auto;
}

.roomsContainer {
    position: relative;

    display: flex;
    flex-direction: column;

    align-items: center;

    padding: spacing(1, 2) spacing(2, 4);
}
