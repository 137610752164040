
$space-base: 8px;

$header-size: 65px;

$footer-size: 18px;

@function spacing($multiplier: 1, $correction: 0px) {
    @return $space-base * $multiplier + $correction;
}

@function px-to-em($pixelsize) {
    @return $pixelsize/16 * 1em;
}

$screen-smallest: 768px;
$screen-desktop: 1024px;
$screen-ultra-wide: 2048px;

@mixin media-query($breakpoint) {
    $ultra-wide-breakpoint: '(min-width: #{$screen-ultra-wide})';
    $tablet-breakpoint: '(max-width: #{$screen-desktop - 1px})';
    $mobile-breakpoint: '(max-width: #{$screen-smallest - 1px})';

    @if ($breakpoint == ultra-wide) {
        @media #{$ultra-wide-breakpoint} {
            @content;
        }
    }

    @if ($breakpoint == tablet) {
        @media #{$tablet-breakpoint} {
            @content;
        }
    } @else if ($breakpoint == mobile) {
        @media #{$mobile-breakpoint} {
            @content;
        }
    } @else {
        @media (max-width: #{$breakpoint}) {
            @content;
        }
    }
}

@mixin visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
}
