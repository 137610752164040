@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.wrapper {
    display: flex;

    flex-direction: column;

    width: 100%;
    height: 100%;

    background-color: $base-background-color;

    padding: spacing(2, 4);
}

.formWrapper {
    background-color: $base-background-color;
    border-radius: 4px;
    display: flex;
    max-width: 384px;
    width: 100%;
    min-height: 480px;
    margin: auto;
    align-items: center;
    flex-direction: column;

    padding: spacing(3);

    @include media-query(mobile) {
        max-width: 100%;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: center;
}

.submitButton {
    margin-top: spacing(3, -4px);
    margin-bottom: spacing(3, -4px);
}

.errorMessage {
    margin-top: spacing(3, -4px);
    opacity: 0, 8;
}

.title {
    margin-bottom: spacing(1, 0);
}
