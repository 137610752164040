@import "../../../../styles/variables/colors";
@import "../../../../styles/variables/layout";

.container {
    position: relative;
    width: 100%;
    height: 140px;
    padding: spacing(2, 4);
    border-radius: 8px;
    box-shadow: 1px 2px 6px 0 $base-shadow-color;
    background-color: $blue-20;

    &:not(:last-child) {
        margin-bottom: spacing(1, 2);
    }

    display: flex;
    justify-content: space-between;

    cursor: pointer;
}

.img {
    width: 100px;
    height: 100px;
}

.geo {
    display: flex;
    flex-direction: column;
    margin-left: spacing(2, 4);
    flex-grow: 1;
    justify-content: center;
}
