/* ------------------------------------- */

$gr-body: #2a2e33;
$gr-caption: #848e9b;
$gr-med: #dadfe7;
$schoonster-orange-100: #ef4123;
$schoonster-orange-70: #f47a65;
$schoonster-orange-20: #fcd9d3;
$violet: #8c6dc0;
$violet-20: #e8e2f3;
$green: #48a790;
$blue-20: #def2fd;
$white: #ffffff;
$white-5: rgba(255, 255, 255, 0.5);

$base-background-color: $white;
$base-text-color: $gr-body;
$secondary-text-color: $gr-caption;
$base-border-color: $gr-caption;
$base-focus-color: $schoonster-orange-70;
$base-placeholder-color: $gr-caption;
$base-shadow-color: $gr-med;
$base-button-color: $schoonster-orange-70;
$secondary-button-color: $white;
