@import "../../../../styles/variables/colors";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

.generalLogo {
    width: 154px;
    height: 25px;
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ef4123;
  }
  