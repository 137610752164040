@import 'src/styles/variables/layout';
@import 'src/styles/variables/colors';

.container {
    width: 100%;
    background-color: $base-background-color;
    height: $footer-size;
    display: flex;
    align-items: center;
}

.logo {
    margin: 0 auto;
}
